import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date'
import getUrlExtensionMixin from '@/mixins/get-url-extension.js'

export default {
    name: 'ColorFigures',
    data() {
        return {
            colorsFigures: {},
            scopes: []
        }
    },
    components: {
        adminLayout
    },
    mounted() {
        this.getColorsFigures()
    },
    mixins: [formatDateMixin, getUrlExtensionMixin],
    methods: {
        getColorsFigures() {
            const self = this;
            const scopes = 'scope=' + this.scopes.join(',')

            httpAxios({
                url: this.$backendUrl + '/api/v1/colors-figures' + '?' + scopes,
                method: 'GET'
            }).then(response => {
                self.colorsFigures = response.data.rows;
            })
        },
        updateScopes(name) {
            this.scopes = [name]

            this.getColorsFigures()
        }
    }
}
